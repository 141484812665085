body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.facebook-login-button {
  display: inline-flex;
  align-items: center;
  background-color: #1877f2;
  border: none;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.625rem 1rem;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.facebook-icon {
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.facebook-login-button:hover {
  background-color: #166fe5;
}
