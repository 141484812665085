.message-container {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
  min-width: 250px;
  max-width: 90%;
}

.toast {
  opacity: 0;
  animation: fadeInDown 0.5s ease forwards;
  border-radius: 1rem; /* Rounded corners for the entire toast */
  overflow: hidden; /* Ensures content doesn't overflow rounded corners */
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast-header {
  background-color: #f8d7da;
  color: #721c24;
  border-bottom: 1px solid #f5c6cb;
  border-top-left-radius: 1rem; /* Rounded top corners */
  border-top-right-radius: 1rem;
}

.toast-body {
  background-color: #fff;
  color: #721c24;
  border-bottom-left-radius: 1rem; /* Rounded bottom corners */
  border-bottom-right-radius: 1rem;
}

.btn-close {
  font-size: 0.875rem;
}
