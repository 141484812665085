/* set correct box model */
* {
  box-sizing: border-box;
}

/* flexbox container */
.__page-layout-flexbox {
  display: flex;
  flex-wrap: wrap;
}

/* columns (mobile) */
.__page-layout-flexbox > * {
  width: 100%;
  padding: 1rem;
}

.__header {
  min-height: 5vh;
  margin-bottom: 2vh;
}
.__main-content {
  min-height: 80vh;
  background: #fff;
}

.__footer {
  min-height: 10vh;
  margin-top: 2vh;
}
